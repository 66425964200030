@import "~base/variables";
@import "~base/experience/components/commerceLayouts/carousel";
@import "../../carousel/mediumCarousel";
@import "~base/components/hero";

/* Remove Horizontal Scroll */
.experience-commerce_layouts-carousel {
    overflow: hidden;
}

.carousel-control-next,
.carousel-control-prev {
    @include media-breakpoint-down(sm) {
        z-index: 15;
        opacity: 1;
    }
}

.carousel-item {
    @include media-breakpoint-down(sm) {
        margin-right: -100%;
    }

    .container-fluid & {
        padding-left: 0;
        padding-right: 0;
    }
}

.experience-einstein-einsteinCarousel .carousel-control-prev,
.experience-einstein-einsteinCarousel .carousel-control-next,
.experience-einstein-einsteinCarouselCategory .carousel-control-next,
.experience-einstein-einsteinCarouselCategory .carousel-control-prev,
.experience-einstein-einsteinCarouselProduct .carousel-control-prev,
.experience-einstein-einsteinCarouselProduct .carousel-control-next,
.experience-commerce_layouts-carousel .carousel-control-prev,
.experience-commerce_layouts-carousel .carousel-control-next {
    background-color: rgba($black, 0.4);
    top: 50%;
    margin-top: -2.625rem;

    @include media-breakpoint-down(sm) {
        top: 36%;
    }
}

.experience-einstein-einsteinCarousel .carousel-control-prev,
.experience-einstein-einsteinCarouselCategory .carousel-control-prev,
.experience-einstein-einsteinCarouselProduct .carousel-control-prev,
.experience-commerce_layouts-carousel .carousel-control-prev {
    left: 0;
}

.experience-einstein-einsteinCarousel .carousel-control-next,
.experience-einstein-einsteinCarouselCategory .carousel-control-next,
.experience-einstein-einsteinCarouselProduct .carousel-control-next,
.experience-commerce_layouts-carousel .carousel-control-next {
    right: 0;
}

.carousel-indicators {
    li {
        background-color: #ccc;
    }
}
