@import "../../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "../../carousel/extraSmallCarousel";
@import "../../carousel/mediumCarousel";
@import "../../carousel/smallCarousel";

.carousel-item {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
    padding-bottom: 0.9375rem;
    margin-right: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.experience-einstein-einsteinCarousel .carousel-inner,
.experience-einstein-einsteinCarouselCategory .carousel-inner,
.experience-einstein-einsteinCarouselProduct .carousel-inner,
.experience-commerce_layouts-carousel .carousel-inner {
    width: auto;
}

.experience-commerce_layouts-carousel .carousel-control-next {
    display: none;
}

.experience-commerce_layouts-carousel .carousel-control-prev {
    display: none;
}

.experience-einstein-einsteinCarousel .carousel-control-prev,
.experience-einstein-einsteinCarousel .carousel-control-next,
.experience-einstein-einsteinCarouselCategory .carousel-control-next,
.experience-einstein-einsteinCarouselCategory .carousel-control-prev,
.experience-einstein-einsteinCarouselProduct .carousel-control-prev,
.experience-einstein-einsteinCarouselProduct .carousel-control-next,
.experience-commerce_layouts-carousel .carousel-control-prev,
.experience-commerce_layouts-carousel .carousel-control-next {
    position: absolute;
    background-color: $gray-transparent-1;
    height: 4.6875rem;
    width: 3.125rem;
    top: 30%;
}

.experience-einstein-einsteinCarousel .carousel-control-prev,
.experience-einstein-einsteinCarouselCategory .carousel-control-prev,
.experience-einstein-einsteinCarouselProduct .carousel-control-prev,
.experience-commerce_layouts-carousel .carousel-control-prev {
    left: -0.625rem;

    .carousel-control-prev-icon {
        position: absolute;
        left: 25%;
    }
}

.experience-einstein-einsteinCarousel .carousel-control-next,
.experience-einstein-einsteinCarouselCategory .carousel-control-next,
.experience-einstein-einsteinCarouselProduct .carousel-control-next,
.experience-commerce_layouts-carousel .carousel-control-next {
    right: -0.625rem;

    .carousel-control-next-icon {
        position: absolute;
        right: 25%;
    }
}

.pd-carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0.625rem;
    left: 0;
    z-index: 15;
    display: none;
    justify-content: center;
    padding-left: 0; // override <ol> default
    // Use the .carousel-control's width as margin so we don't overlay those
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;

    li {
        position: relative;
        flex: 0 1 auto;
        width: 30px;
        height: 0.187rem;
        margin-right: 0.187rem;
        margin-left: 0.187rem;
        text-indent: -62.437rem;
        cursor: default;
        background-color: $gray-4;

        // Use pseudo classes to increase the hit area by 10px on top and bottom.
        &::before {
            position: absolute;
            top: -0.625rem;
            left: 0;
            display: inline-block;
            width: 100%;
            height: 0.625rem;
            content: "";
        }

        &::after {
            position: absolute;
            bottom: -0.625rem;
            left: 0;
            display: inline-block;
            width: 100%;
            height: 0.625rem;
            content: "";
        }
    }

    .active {
        background-color: $black;
    }
}
