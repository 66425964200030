@import "~base/experience/carousel/mediumCarousel";

@include media-breakpoint-up(md) {
    .carousel-md-2.indicators-md {
        .carousel-control-prev,
        .carousel-control-next {
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

@include media-breakpoint-only(xs) {
    .carousel.controls-xs,
    .carousel-xs-2.indicators-xs {
        .carousel-control-next,
        .carousel-control-prev {
            z-index: 9;
        }
    }
}
